import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  computed,
  input,
} from '@angular/core';
import { Maybe, UpdateDeviceGQL } from '@designage/gql';
import {
  ChannelService,
  DeviceDataService,
  ToasterService,
} from '@desquare/services';
import { DeviceData } from '@desquare/types';
import { TranslateModule } from '@ngx-translate/core';
import { lastValueFrom } from 'rxjs';
import {
  IDeviceCommandItem,
  IDeviceCommandsComponentOptions,
  initDeviceCommandsOptions,
} from './device-commands.model';

@Component({
  standalone: true,
  imports: [NgClass, TranslateModule],
  selector: 'app-device-commands',
  template: `
    <div
      [ngClass]="{
        expanded: isExpandedLayout,
        'compact btn-group': isCompactLayout,
      }"
    >
      @for (command of deviceCommonCommands; track command.label) {
        @if (command.show) {
          <button
            class="
      btn btn-sm btn-outline-light
      d-flex
      align-items-center
      justify-content-start
    "
            [disabled]="command.disable"
            (click)="command.callback()"
          >
            <i [class]="command.iconClassName"></i>
            @if (isExpandedLayout()) {
              <div class="ms-1">
                {{ command.label | translate }}
              </div>
            }
          </button>
        }
      }
      <hr />
      @for (command of deviceCommands; track command.label) {
        @if (command.show) {
          <button
            class="
      btn btn-sm btn-outline-warning
      d-flex
      align-items-center
      justify-content-start
    "
            [disabled]="command.disable"
            (click)="command.callback()"
          >
            <i [class]="command.iconClassName"></i>
            @if (isExpandedLayout()) {
              <div class="ms-1">
                {{ command.label | translate }}
              </div>
            }
          </button>
        }
      }
    </div>
  `,
  styles: [
    `
      .compact,
      .expanded {
        display: flex;

        > button {
          text-align: center;
        }
      }

      .compact {
        flex-direction: row;
        > button.btn {
          flex-grow: 0;
        }
      }

      .expanded {
        flex-direction: column;
        > button.btn:not(:last-child) {
          margin-bottom: 4px;
        }
        > button.btn {
          border-radius: 0.25rem;
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeviceCommandsComponent implements OnInit {
  deviceCommonCommands: IDeviceCommandItem[] = [];
  deviceCommands: IDeviceCommandItem[] = [];
  layout = input<'compact' | 'expanded'>('compact');
  device = input.required<DeviceData>();
  channelId = input<string | null>(null);
  options = input<IDeviceCommandsComponentOptions>(initDeviceCommandsOptions());

  isExpandedLayout = computed(() => this.layout() === 'expanded');
  isCompactLayout = computed(() => this.layout() === 'compact');

  constructor(
    private deviceDataService: DeviceDataService,
    private channelService: ChannelService,
    private updateDeviceGQL: UpdateDeviceGQL,
    private toasterService: ToasterService,
  ) {}

  ngOnInit(): void {
    this.initDeviceCommands();
  }

  initDeviceCommands() {
    this.deviceCommonCommands = [
      this.createCommandItemReloadApplet(this.device()),
      this.createCommandItemReboot(this.device()),
      this.createCommandItemTurnOnScreen(this.device()),
      this.createCommandItemTurnOffScreen(this.device()),
      this.createCommandItemRefreshInfo(this.device()),
    ];
    this.deviceCommands = [
      this.createCommandItemUpdateApplet(this.device(), this.channelId()),
      this.createCommandItemRepublishContent(this.channelId()),
      this.createCommandItemClearCache(this.device()),
    ];
  }

  private createCommandItemReboot(device: DeviceData): IDeviceCommandItem {
    const options = this.options();

    return {
      label: 'REBOOT',
      iconClassName: 'ri-restart-line',
      get show() {
        return options.controls.reboot?.show ?? true;
      },
      get disable() {
        return options.controls.reboot?.disable;
      },
      callback: () => {
        // Reboot DeviceData
        this.deviceDataService.rebootDevice(device.id);
      },
    };
  }

  private createCommandItemReloadApplet(
    device: DeviceData,
  ): IDeviceCommandItem {
    const options = this.options();

    return {
      label: 'APPLET_RELOAD',
      iconClassName: 'ri-tv-2-line',
      get show() {
        return options.controls.reloadApplet?.show ?? true;
      },
      get disable() {
        return options.controls.reloadApplet?.disable;
      },
      callback: () => {
        // Reload Applet
        this.deviceDataService.reloadDeviceApp(device.id);
      },
    };
  }

  private createCommandItemUpdateApplet(
    device: DeviceData,
    channelId: string | null,
  ): IDeviceCommandItem {
    const options = this.options();

    return {
      label: 'APPLET_UPDATE_VERSION',
      iconClassName: 'ri-list-ordered',
      get show() {
        return options.controls.updateApplet?.show ?? true;
      },
      get disable() {
        return options.controls.updateApplet?.disable;
      },
      callback: async () => {
        if (!channelId) return;

        // Update Screen Applet Version ?
        // TODO: the command is located in channel-list.component
        const deviceId = device.id;
        const name = device.name;
        const appletVersion = device.signageOSDeviceTiming?.activeAppletVersion;

        const result = await lastValueFrom(
          this.updateDeviceGQL.mutate(
            {
              input: {
                id: deviceId,
                name,
                channelId,
                appletVersion,
              },
            },
            {
              errorPolicy: 'ignore',
            },
          ),
        );

        if (result.data?.updateDevice.isSuccessful) {
          this.toasterService.success('UPDATE_DEVICE_SUCCESSFUL');
        } else {
          this.toasterService.error('UPDATE_DEVICE_ERROR');
        }
      },
    };
  }

  private createCommandItemRepublishContent(
    channelId: string | null,
  ): IDeviceCommandItem {
    const options = this.options();

    return {
      label: 'REPUBLISH_PLAYLISTS',
      iconClassName: 'ri-play-list-line',
      get show() {
        return options.controls.republishContent?.show ?? true;
      },
      get disable() {
        return options.controls.republishContent?.disable;
      },
      callback: () => {
        // Republish ?Playlists || Content?
        // this.channelDataService.republishChannelContent(this.device.id);
        if (channelId) this.channelService.republishChannelContent([channelId]);
      },
    };
  }

  private createCommandItemTurnOnScreen(
    device: DeviceData,
  ): IDeviceCommandItem {
    const options = this.options();

    return {
      label: 'DISPLAY_ON',
      iconClassName: 'ri-shut-down-fill',
      get show() {
        return options.controls.turnOnScreen?.show ?? true;
      },
      // get disable() {
      //   return options.controls.turnOnScreen?.disable;
      // },
      callback: () => {
        // Turn On ?DeviceData || Screen?
        this.deviceDataService.turnScreenOn(device.id);
      },
    };
  }

  private createCommandItemTurnOffScreen(
    device: DeviceData,
  ): IDeviceCommandItem {
    const options = this.options();

    return {
      label: 'DISPLAY_OFF',
      iconClassName: 'ri-shut-down-line',
      get show() {
        return options.controls.turnOffScreen?.show ?? true;
      },
      // get disable() {
      //   return options.controls.turnOffScreen?.disable;
      // },
      callback: () => {
        // Turn Off ?DeviceData || Screen?
        this.deviceDataService.turnScreenOff(device.id);
      },
    };
  }

  private createCommandItemClearCache(device: DeviceData): IDeviceCommandItem {
    const options = this.options();

    return {
      label: 'CLEAR_DEVICE_CACHE',
      iconClassName: 'ri-brush-3-line',
      get show() {
        return options.controls.clearCache?.show ?? true;
      },
      get disable() {
        return options.controls.clearCache?.disable;
      },
      callback: () => {
        // Clear DeviceData Cache
        this.deviceDataService.bulkClearDeviceCache([device.id]);
      },
    };
  }

  private createCommandItemRefreshInfo(device: DeviceData): IDeviceCommandItem {
    const options = this.options();
    return {
      label: 'REFRESH_DEVICE_INFO',
      iconClassName: 'ri-information-line',
      get show() {
        return options.controls.refreshInfo?.show ?? true;
      },
      get disable() {
        return options.controls.refreshInfo?.disable;
      },
      callback: () => {
        // Refresh Device Info
        this.deviceDataService.refreshDeviceInfo(device.id);
      },
    };
  }
}
